

table.dataTable th.dt-left,
table.dataTable td.dt-left {
    text-align: left
}

table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
    text-align: center
}

table.dataTable th.dt-right,
table.dataTable td.dt-right {
    text-align: right
}

table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
    text-align: justify
}

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
    white-space: nowrap
}

table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
    text-align: left
}

table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
    text-align: center
}

table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
    text-align: right
}

table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
    text-align: justify
}

table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
    white-space: nowrap
}

table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
    text-align: left
}

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
    text-align: center
}

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
    text-align: right
}

table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
    text-align: justify
}

table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
    white-space: nowrap
}

table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0
}

table.dataTable td,
table.dataTable th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
    text-align: center
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
    white-space: nowrap
}

div.dataTables_wrapper div.dataTables_length label {
    font-weight: normal;
    text-align: left;
    white-space: nowrap
}

div.dataTables_wrapper div.dataTables_length select {
    width: auto;
    display: inline-block
}

div.dataTables_wrapper div.dataTables_filter {
    text-align: right
}

div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left
}

div.dataTables_wrapper div.dataTables_filter input {
    margin-left: .5em;
    display: inline-block;
    width: auto
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: .85em
}

div.dataTables_wrapper div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end
}

div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0
}

table.dataTable>thead>tr>th:active,
table.dataTable>thead>tr>td:active {
    outline: none
}

table.dataTable>thead>tr>th:not(.sorting_disabled),
table.dataTable>thead>tr>td:not(.sorting_disabled) {
    padding-right: 5px
}



div.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important
}

div.dataTables_scrollBody>table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

div.dataTables_scrollBody>table>tbody tr:first-child th,
div.dataTables_scrollBody>table>tbody tr:first-child td {
    border-top: none
}

div.dataTables_scrollFoot>.dataTables_scrollFootInner {
    box-sizing: content-box
}

div.dataTables_scrollFoot>.dataTables_scrollFootInner>table {
    margin-top: 0 !important;
    border-top: none
}

@media screen and (max-width: 767px) {

    div.dataTables_wrapper div.dataTables_length,
    div.dataTables_wrapper div.dataTables_filter,
    div.dataTables_wrapper div.dataTables_info,
    div.dataTables_wrapper div.dataTables_paginate {
        text-align: center
    }

    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
        justify-content: center !important
    }
}

table.table-bordered.dataTable {
    border-right-width: 0
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
    border-left-width: 0
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
    border-right-width: 1px
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
    border-bottom-width: 0
}

div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0
}

div.table-responsive>div.dataTables_wrapper>div.row {
    margin: 0
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:first-child {
    padding-left: 0
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
    padding-right: 0
}