html,
body {
    /* font-family: 'Fira Sans', sans-serif; */
    /* font-family: 'EB Garamond', serif; */
    /* font-family: 'Abhaya Libre', serif; */
    /* font-family: 'Merriweather', serif; */
    font-family: 'Hahmlet', serif;
    /* font-weight: 300; */
    font-size: 16px;
    line-height: 1.5rem;
    background-color: #fff;
}

::-webkit-scrollbar {
    width: 0
}

:root {
    --hw: #3B82F6;
}

.row {
    margin: 0;
    padding: 0;
}

img {
    object-fit: cover;
}

#overlay {
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
}

.alignLeft {
    display: flex;
    justify-content: left;
    align-items: flex-start;
}

.alignTextLeft {
    text-align: left;
}

.h-350-px {
    height: 270px;
}


@media(min-width: 400px) {
    .company-title {
        font-size: 22px;
    }
}


@media(max-width: 400px) {
    .company-title {
        font-size: 17px;
    }
}

.div-employees-page-title {
    background-color: #eee;
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
}

@media(max-width: 500px) {
    .footer-social-media {
        padding-bottom: 15px;
    }

    .home-page-title {
        font-weight: bold;
        font-size: 25px;
    }

    .card-stats {
        padding-bottom: 2px;
    }

    .invoices-page-title {
        font-weight: bold;
        font-size: 23px;
        padding-top: 25px;
    }

    .div-employees-page-title {
        min-height: 80px;
        padding-top: 10px;
    }

    .padding-data-table {
        padding-left: 10px;
        padding-right: 10px;
    }

    .create-invoice-btn {
        position: absolute;
        top: 75vh;
    }

    .dataTables_wrapper th {
        font-size: 18px;
    }

    .dataTables_wrapper tbody {
        font-size: 16px;
    }

    .table-th {
        font-size: 10px;
    }

    .table-tbody {
        font-size: 16px;
        font-weight: 200;
    }

    button i {
        padding-right: 1vw;
    }
}

@media(min-width: 768px) {
    .padding-for-bottom-navigation {
        padding-bottom: 20px;
    }

    .padding-for-dashboard {
        padding-bottom: 1px;
    }
}

@media(max-width: 768px) {
    .padding-for-bottom-navigation {
        padding-bottom: 80px;
    }

    .padding-for-dashboard {
        padding-bottom: 60px;
    }
}


@media(min-width: 500px) {
    .home-page-title {
        font-weight: bold;
        font-size: 40px;
    }

    .padding-data-table {
        padding-left: 20px;
        padding-right: 20px;
    }

    .card-stats {
        padding-bottom: 25px;
    }

    .invoices-page-title {
        font-weight: bold;
        font-size: 35px;
        padding-top: 15px;
    }

    .div-employees-page-title {
        min-height: 80px;
    }

    .create-invoice-btn {
        position: absolute;
        top: 85vh;
    }

    .dataTables_wrapper th {
        font-size: 16px;
    }

    .dataTables_wrapper tbody {
        font-size: 15px;
    }

    .dataTables_wrapper tr {
        cursor: pointer;
    }


    .table-th {
        font-size: 10px;
    }

    .table-tbody {
        font-size: 15px;
        font-weight: 200;
    }
    button i {
        padding-right: 0.4vw;
    }

}

/* for MDB data tables, hide a column at position 1 */
/* .dataTables_wrapper tr td:nth-child(1), 
.dataTables_wrapper th:nth-child(1) {
    display: none;
} */

.dataTables_wrapper th {
    /* text-align: center; */
    padding-left: 1.5vw;
}

@media(min-width: 800px) {
    .employee-details-container {
        width: 100%;
        padding-top: 130px;
        padding-bottom: 30px;
        background-color: white;
    }

    .profile-picture-container {
        min-height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 5.3%;
    }

    .profile-picture {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border: 3.5px solid antiquewhite;
    }

}

@media(max-width: 799px) {
    .employee-details-container {
        width: 100%;
        padding-top: 108px;
        padding-bottom: 15px;
    }

    .profile-picture-container {
        min-height: 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 2.75%;
    }

    .profile-picture {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        border: 3px solid antiquewhite;
    }

}

@media(max-width: 500px) {
    .employee-details-container {
        width: 100%;
        padding-top: 90px;
        padding-bottom: 10px;
    }

    .profile-picture-container {
        min-height: 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 2.7%;
    }

    .profile-picture {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        border: 2px solid antiquewhite;
    }

}

.add-employer {
    padding-left: 3px;
    cursor: pointer;
}

.add-employer:hover {
    text-decoration: underline;
}

@media(min-width: 800px) {
    .agency-details-container {
        width: 100%;
        padding-top: 130px;
        padding-bottom: 30px;
        background-color: white;
    }

    .agency-profile-picture-container {
        min-height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 6.5%;
    }

    .agency-profile-picture {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border: 3.5px solid antiquewhite;
    }

    .ps-profile-picture {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border: 3.5px solid antiquewhite;
        position: relative;
    }

    .ps-profile-picture .profile-icon {
        position: absolute;
        right: 0;
        bottom: 20px;
        padding: 10px;
        background: #eee;
        border-radius: 50%;
        border: 1px solid #faebd7;
    }

}

@media(max-width: 799px) {
    .agency-details-container {
        width: 100%;
        padding-top: 108px;
        padding-bottom: 15px;
    }

    .agency-profile-picture-container {
        min-height: 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 4.65%;
    }

    .agency-profile-picture {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        border: 3px solid antiquewhite;
    }

    .ps-profile-picture {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        border: 3px solid antiquewhite;
        position: relative;
    }

    .ps-profile-picture .profile-icon {
        position: absolute;
        right: 0;
        bottom: 20px;
        padding: 10px;
        background: #eee;
        border-radius: 50%;
        border: 1px solid #faebd7;
    }

}

@media(max-width: 500px) {
    .agency-details-container {
        width: 100%;
        padding-top: 90px;
        padding-bottom: 10px;
    }

    .agency-profile-picture-container {
        min-height: 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 4.3%;
    }

    .agency-profile-picture {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        border: 2px solid antiquewhite;
    }

    .ps-profile-picture {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        border: 3px solid antiquewhite;
        position: relative;
    }

    .ps-profile-picture .profile-icon {
        position: absolute;
        right: 0;
        bottom: 20px;
        padding: 10px;
        background: #eee;
        border-radius: 50%;
        border: 1px solid #faebd7;
    }

}

@media(min-width: 800px) {
    .payment-results-text {
        font-size: 20px;
    }
}

@media(max-width: 799px) {
    .payment-results-text {
        font-size: 18px;
    }

    .profile-bottom {
        padding-bottom: 5rem !important;        
    }
}

@media(max-width: 500px) {
    .payment-results-text {
        font-size: 14px;
    }
}

@media(max-width: 360px) {
    .employees-page-title {
        font-weight: bold;
        font-size: 16px;
    }

    .employees-page-title button {
        font-size: 12px;
        /* margin-left: 5vw; */
    }

    .invoice-page-title {
        font-weight: bold;
        font-size: 16px;
    }

    .invoice-page-title button {
        font-size: 12px;
        /* margin-left: 5vw; */
    }

    button i {
        font-size: 12px;
    }
}

@media(min-width: 361px) {
     .employees-page-title {
        font-weight: bold;
        font-size: 23px;
    }

    .employees-page-title button {
        font-size: 18px;
        /* margin-left: 5vw; */
    }

    .invoice-page-title {
        font-weight: bold;
        font-size: 23px;
    }

    .invoice-page-title button {
        font-size: 18px;
        /* margin-left: 5vw; */
    }

    button i {
        font-size: 12px;
    }

}

@media(min-width: 751px) {
     .employees-page-title {
        font-weight: bold;
        font-size: 35px;
    }

    .employees-page-title button {
        font-size: 23px;
        /* margin-left: 8vw; */
    }

    .invoice-page-title {
        font-weight: bold;
        font-size: 29px;
    }

    .invoice-page-title button {
        font-size: 23px;
        /* margin-left: 8vw; */
    }

    button i {
        font-size: 15px;
    }

}

/* phone screen */
.resend-invoice {
    font-weight: lighter;
    word-spacing: 1px;
    line-height: 1; 
    margin-left: 1vw;
}

@media screen and (min-device-width: 360px) and (max-device-width: 420px) {
    .resend-invoice {
        font-weight: lighter;
        word-spacing: 1px;
        line-height: 1; 
        margin-left: 2vw;
        font-size: 12px;
    }
}

@media screen and (min-device-width: 420px) and (max-device-width: 576px) {
    .resend-invoice {
        font-weight: lighter;
        word-spacing: 1px;
        line-height: 1; 
        margin-left: 8vw;
    }
}

@media screen and (min-device-width: 576px) and (max-device-width: 768px) {
    .resend-invoice {
        font-weight: lighter;
        word-spacing: 1px;
        line-height: 1; 
        margin-left: 9vw;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
    .resend-invoice {
        font-weight: lighter;
        word-spacing: 1px;
        line-height: 1; 
        margin-left: 9vw;
    }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1200px) {
    .resend-invoice {
        font-weight: lighter;
        word-spacing: 1px;
        line-height: 1; 
        margin-left: 9vw;
    }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1400px) {
    .resend-invoice {
        font-weight: lighter;
        word-spacing: 1px;
        line-height: 1; 
        margin-left: 9vw;
    }
}
    
.profile-button {
    position: relative;
}

.profile-button > .profile-image{
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    /* width: 194px; */
    width: 100%;
    opacity: 0.9;
    /* height: 194px; */
    height: 100%;
    color: #fffefe;
    font-weight: 600;
}

.profile-button:hover > .profile-image {
    z-index: 1;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    /* width: 194px; */
    width: 100%;
    background: rgba(84,101,111,0.8);
    /* height: 194px; */
    height: 100%;
    color: #fffefe;
    font-weight: 600;
    transform: translateZ(0);
    transition: z-index 0.3s cubic-bezier(1, 0, 1, 0);
}

.profile-image span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-transform: uppercase;
}
